import axiosIns from "@/axios";

const get = async () => {
  return await axiosIns.get(`empresas`)
}
const ActualizarToken = async ( data) => {
  return await axiosIns.patch(`empresas`, data);
}
const subirFirmaDigital = async (data) => {
  // const formData = new FormData();
  // formData.append("archivo", archivo);
// console.log("formData ",data);
  return await axiosIns.post(`/empresas/uploadcertificado`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
const getID = async (id) => {
  return await axiosIns.get(`facturas/${id}`)
}
const anularFactura = async (data) => {
  return await axiosIns.post("facturas/anular",data)
}
const ValidarFactura = async (id)=> {
  return await axiosIns.get(`facturas/verificar/${id}`)
}
const update = async (id, data) => {
  return await axiosIns.put(`sucursales/${id}`, data);
}
const getId = async (id) => {
  return await axiosIns.get(`puntosventas/${id}`);
}
const getCodigoSucursal= async (cod) => {
  return await axiosIns.get(`puntosventas/codigosucursal/${cod}`);
}
const getTipoPuntoVenta= async () => {
  return await axiosIns.get(`catalogos/11`);
}
const create = async (data) => {
  return await axiosIns.post("puntosventas", data);
}
const createCuis = async (data) => {
  return await axiosIns.post("cuis", data);
}
const createCufds = async (data) => {
  return await axiosIns.post("cufds", data);
}
const getCufds=async (sucursalCod,puntoVentaCod,params)=>{
  console.log("params",params)
  return await axiosIns.get(`cufds/sucursal/${sucursalCod}/puntoventa/${puntoVentaCod}?size=${params.size}&page=${params.page}`);
  // return await axiosIns.get(`cufds/sucursal/${sucursalCod}/puntoventa/${puntoVentaCod}?size=38&page=0`);
}
export default {
    update,
    ActualizarToken,
    subirFirmaDigital,
    get,
    getID,
    create,
    getId,
    getCodigoSucursal,
    getTipoPuntoVenta,
    createCuis,
    createCufds,
    getCufds,
    anularFactura,
    ValidarFactura
    // store,
    // update,
    // destroy,
    // findOne,
    // enviarProyectista
  }