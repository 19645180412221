export default [
    {
        path: '/facturas/sucursalcod/:sucursalCod/codigoPv/:puntoVentaCod/documentoSector/:docSevtorCod',
        name: 'facturas',
        component: () => import('@/modules/facturacion/facturas/views/ListadoFacturaFiltroViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/facturas',
        name: 'facturas-filtro', 
        component: () => import('@/modules/facturacion/facturas/views/ListadoFacturaFiltroViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/facturas/paquete/:paqueteid',
        name: 'facturas-paquete',
        component: () => import('@/modules/facturacion/facturas/components/ListarFacturasPaquete.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
];