export default [
    {
        path: '/cuis/sucursal/:sucursalCod/puntoVenta/:puntoVentaCod',
        name: 'cuis',
        component: () => import('@/modules/facturacion/cuis/views/ListadoCuisViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    }, 
    {
        path: '/cuis',
        name: 'cuiss',
        component: () => import('@/modules/facturacion/cuis/views/ListadoCuisViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },   
];