export default [
    {
        path: '/puntosDeVentas',
        name: 'puntos-de-ventas',
        component: () => import('@/modules/facturacion/puntosDeVentas/views/ListadoFiltradoViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/puntosDeVentas/sucursal/:cod',
        name: 'puntos-de-ventas-sucursal',
        component: () => import('@/modules/facturacion/puntosDeVentas/components/ListarPuntosDeVentasFiltrado.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    // {
    //     path: '/puntosDeVentas/cufds/:sucursalCod/:puntoVenCod',
    //     name: 'cufds',
    //     component: () => import('@/modules/facturacion/puntosDeVentas/components/ListarCufds.vue'),
    //     meta: {
    //         slug: 'area-ver', // para los permisos
    //     },
    // },
    // {
    //     path: '/cuis/sucursal/:sucursalCod/codigoPv/:puntoVenCod',
    //     name: 'cuis',
    //     component: () => import('@/modules/facturacion/puntosDeVentas/components/ListarCuis.vue'),
    //     meta: {
    //         slug: 'area-ver', // para los permisos
    //     },
    // },
];