import axios from 'axios';

const axiosIns = axios.create({    
    baseURL: process.env.VUE_APP_URL_API,
    // withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apikey: process.env.VUE_APP_API_KEY
      // apikey:"eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MDYyMDg3NjAsImV4cCI6MTczNzc0NDc2MCwibml0Ijo1MTIxMzcyMDEzLCJyYXpvbiBzb2NpYWwiOiJNRUxJU1NBIFpVTE1BIFZJREVaIEFSR0FORE_DkUEiLCJzaXN0ZW1hIjoic2ktZXNhbTEuMCJ9.JCxGDc9FLR0kPT3DIqSxldHzaW8gmVRqUC6CSVzoKGI"
      // apikey:"eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MDY4MDAyOTYsImV4cCI6MTczODMzNjI5Niwibml0IjoxNzIwNTYwMjcsInJhem9uIHNvY2lhbCI6IkImUyBRVUFMSVRZIFNPQ0lFREFEIERFIFJFU1BPTlNBQklMSURBRCBMSU1JVEFEQSBCJlMgUVVBTElUWSBTLlIuTC4iLCJzaXN0ZW1hIjoic2ktZXNhbTEuMCJ9.uYoXq_E82gli5tEG5UpLMp4AFdKJa4yWd9b5a4DbZw8"
      // apikey:"eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTE1NTI1MjYsImV4cCI6MTc0MzA4ODUyNiwibml0Ijo0MTQ2NTAwMjQsInJhem9uIHNvY2lhbCI6IkhPU1FVSU1JQ0FMQUIgUy5SLkwiLCJzaXN0ZW1hIjoic2ktZXNhbTEuMCJ9.yD2rokoBgptOmbGdHUwAb413duqlxaCDxZJj9y42-W0"
      // apikey:process.env.API_KEY
      // apikey:"sdsdfdfddsmdsdsd"
      // Authorization: `Bearer ${process.env.API_KEY}`,
    },
    // headers: { 'X-Requested-With': 'XMLHttpRequest' },
    // headers: { 'X-Custom-Header': 'foobar' },
    // headers: { 'Access-Control-Allow-Origin': '*' },
    // headers: { 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS' },
});

export default axiosIns;