<script setup>
import { ref,onMounted } from 'vue';
import empresaServices from './modules/facturacion/empresa/services/index';
import moment from 'moment';
let empresa=ref(null);
onMounted(() => {
	getEmpresa();
});
const getEmpresa = () => {
    try {
			empresaServices.get().then((data) => {
				empresa.value = data.data.data;
			});
		} catch (err) {
			console.log( err.response)
		}
};
</script>
<template>
	<div class="layout-footer">
		<span class="footer-text-left">
			<div class="flex gap-2">

				<img alt="Logo" src="layout/images/Siesam-dark.png"  />
				<div>
					<Chip class="pl-0 pr-3 mr-3">
						<span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center">T</span>
						<span class="ml-2 font-medium">{{moment(  empresa?.fechaExpiracionToken).format('YYYY-MM-DD HH:mm') }}</span>
					</Chip>
					<Chip class="pl-0 pr-3">
						<span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center">F</span>
						<span class="ml-2 font-medium">{{moment( empresa?.fechaExpiracionFirma).format('YYYY-MM-DD HH:mm') }}</span>
					</Chip>
				</div>
			</div>
		</span>
		<span class="footer-text-right">
			<!-- <div>
				<Chip class="pl-0 pr-3 mr-3">
					<span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center">T</span>
					<span class="ml-2 font-medium">{{moment(  empresa?.fechaExpiracionToken).format('YYYY-MM-DD HH:mm') }}</span>
				</Chip>
				<Chip class="pl-0 pr-3">
					<span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center">F</span>
					<span class="ml-2 font-medium">{{moment( empresa?.fechaExpiracionFirma).format('YYYY-MM-DD HH:mm') }}</span>
				</Chip>
			</div> -->

		</span>
	</div>
</template>

<style scoped>
	@import './assets/demo/styles/badges.scss';

</style>
