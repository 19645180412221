export default [
    {
        path: '/catalogos',
        name: 'catalogos',
        component: () => import('@/modules/facturacion/catalogo/views/CatalogosViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/mensajesSoap',
        name: 'mensajesSoap',
        component: () => import('@/modules/facturacion/catalogo/views/MensajesSoapViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/motivoEvento',
        name: 'motivoEvento',
        component: () => import('@/modules/facturacion/catalogo/views/MotivoEventoViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/motivoAnulacion',
        name: 'motivoAnulacion',
        component: () => import('@/modules/facturacion/catalogo/views/MotivoAnulacionViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/codigoPais',
        name: 'codigoPais',
        component: () => import('@/modules/facturacion/catalogo/views/CodigoPaisViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/TipoDocumentoIdentidad',
        name: 'TipoDocumentoIdentidad',
        component: () => import('@/modules/facturacion/catalogo/views/TipoDocumentoIdentidadViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/TipoDocumentoSector',
        name: 'TipoDocumentoSector',
        component: () => import('@/modules/facturacion/catalogo/views/TipoDocumentoSectorViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/TipoEmision',
        name: 'TipoEmision',
        component: () => import('@/modules/facturacion/catalogo/views/TipoEmisionViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/TipoHabitacion',
        name: 'TipoHabitacion',
        component: () => import('@/modules/facturacion/catalogo/views/TipoHabitacionViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/TipoMetodoPago',
        name: 'TipoMetodoPago',
        component: () => import('@/modules/facturacion/catalogo/views/TipoMetodoPagoViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/TipoMoneda',
        name: 'TipoMoneda',
        component: () => import('@/modules/facturacion/catalogo/views/TipoMonedaViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/TipoPuntoVenta',
        name: 'TipoPuntoVenta',
        component: () => import('@/modules/facturacion/catalogo/views/TipoPuntoVentaViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/TipoFactura',
        name: 'TipoFactura',
        component: () => import('@/modules/facturacion/catalogo/views/TipoFacturaViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/TipoUnidadMedida',
        name: 'TipoUnidadMedida',
        component: () => import('@/modules/facturacion/catalogo/views/TipoUnidadMedidaViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/Actividades',
        name: 'Actividades',
        component: () => import('@/modules/facturacion/catalogo/views/ActividadesViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/Leyendas',
        name: 'Leyendas',
        component: () => import('@/modules/facturacion/catalogo/views/LeyendasViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/ProductoServicio',
        name: 'ProductoServicio',
        component: () => import('@/modules/facturacion/catalogo/views/ProductoServicioViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/ActividadesDocumentoSector',
        name: 'Actividades-documento-sector',
        component: () => import('@/modules/facturacion/catalogo/views/ActividadesDocSectViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
];