export default [
    {
        path: '/eventoSignificativo/sucursal/:sucursalCod/puntoVenta/:puntoVentaCod',
        name: 'evento-significativo',
        component: () => import('@/modules/facturacion/eventoSignificativos/views/EventoSignificativoFiltroViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/eventoSignificativo',
        name: 'evento-significativos',
        component: () => import('@/modules/facturacion/eventoSignificativos/views/EventoSignificativoFiltroViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
];