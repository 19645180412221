<template>
	<div :class="containerClass" @click="onDocumentClick">
		<div class="layout-wrapper-content">
			<AppTopBar :topbarMenuActive="topbarMenuActive" :profileMode="profileMode" :horizontal="layoutMode === 'horizontal'" :activeTopbarItem="activeTopbarItem"
			@menubutton-click="onMenuButtonClick" @topbar-menubutton-click="onTopbarMenuButtonClick" @topbar-item-click="onTopbarItemClick"></AppTopBar>

			<transition name="layout-menu-container">
				<div :class="menuClass" @click="onMenuClick" v-show="isMenuVisible()">
					<div class="menu-scroll-content">
						<AppInlineProfile v-if="profileMode === 'inline' && layoutMode !== 'horizontal'" @profile-click="onProfileClick" :expanded="profileExpanded"></AppInlineProfile>

						<AppMenu :model="menu" :layoutMode="layoutMode" :active="menuActive" :mobileMenuActive="staticMenuMobileActive" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" :style="'color: red !important'"></AppMenu>

					</div>
				</div>
			</transition>
			<div class="layout-main">
				<router-view />
			</div>

			<!-- <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" :darkMenu="darkMenu" @menu-color-change="onMenuColorChange"
						:profileMode="profileMode" @profile-mode-change="onProfileModeChange" :layout="layoutColor" :layouts="layoutColors" @layout-color-change="onLayoutColorChange"
						:theme="theme" :themes="themeColors" @theme-change="onThemeChange"  :layoutScheme='layoutScheme' @layout-scheme-change='changeLayoutScheme'></AppConfig> -->

			<div class="layout-mask"></div>

			<AppFooter />
		</div>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
// import AppConfig from './AppConfig.vue';
import AppInlineProfile from './AppInlineProfile.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';
import EventBus from './event-bus';

export default {
    props: {
        theme: String,
        layoutColor: String,
        layoutScheme: String,
        darkMenu: Boolean,
    },
    data() {
        return {
			layoutMode: 'static',
			profileMode: 'inline',
			overlayMenuActive: false,
			staticMenuDesktopInactive: false,
			staticMenuMobileActive: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
			rotateMenuButton: false,
			menu : [
				// {
				// 	label: 'Favorites', icon: 'pi pi-fw pi-home',
				// 	items: [
				// 		{
				// 			label: 'Dashboards', icon: 'pi pi-fw pi-home',
				// 			items: [
				// 				{label: 'Generic', icon: 'pi pi-fw pi-home', to: '/dashboards/generic'},
				// 				{label: 'Banking', icon: 'pi pi-fw pi-money-bill', to: '/dashboards/dashboard_banking'},
				// 			]
				// 		}
				// 	]
				// },
				{
					label: 'CONFIGURACIÓN', icon: 'pi pi-fw pi-star',
					items: [
						{label: 'Empresa', icon: 'pi pi-fw pi-building', to: '/empresa'},
						{label: 'Sucursales', icon: 'pi pi-fw pi-briefcase', to: '/sucursales/listar'},
						{label: 'Puntos de ventas', icon: 'pi pi-sitemap', to: '/puntosDeVentas'},
						{label: 'Evento Significativo', icon: 'pi pi-fw pi-box', to: '/eventoSignificativo'},
						{label: 'Cuis', icon: 'pi pi-fw pi-box', to: '/cuis'},
						// {label: 'Cufds', icon: 'pi pi-fw pi-box', to: '/cufds'},
						{label: 'Facturas', icon: 'pi pi-fw pi-book', to: '/facturas'},
						{label: 'Cafcs', icon: 'pi pi-fw pi-slack', to: '/Cafcs'},
						{								
							label: 'Catalogos', icon: 'pi pi-fw pi-wallet',
							items: [
								{label: 'Mesajes Soap', icon: 'pi pi-fw pi-credit-card', to: '/mensajesSoap'},
								{label: 'Motivo Evento', icon: 'pi pi-fw pi-credit-card', to: '/motivoEvento'},
								{label: 'Motivo Anulacion', icon: 'pi pi-fw pi-credit-card', to: '/motivoAnulacion'},
								{label: 'Codigo Pais', icon: 'pi pi-fw pi-credit-card', to: '/codigoPais'},
								{label: 'Tp. doc. identidad', icon: 'pi pi-fw pi-credit-card', to: '/TipoDocumentoIdentidad'},
								{label: 'Tp. doc. sector', icon: 'pi pi-fw pi-credit-card', to: '/TipoDocumentoSector'},
								{label: 'Tipo Emision', icon: 'pi pi-fw pi-credit-card', to: '/TipoEmision'},
								{label: 'Tipo Habitacion', icon: 'pi pi-fw pi-credit-card', to: '/TipoHabitacion'},
								{label: 'Tipo metodo pago', icon: 'pi pi-fw pi-credit-card', to: '/TipoMetodoPago'},
								{label: 'Tipo Moneda', icon: 'pi pi-fw pi-credit-card', to: '/TipoMoneda'},
								{label: 'Tipo punto de venta', icon: 'pi pi-fw pi-credit-card', to: '/TipoPuntoVenta'},
								{label: 'Tipo Factura', icon: 'pi pi-fw pi-credit-card', to: '/TipoFactura'},
								{label: 'Tipo Unidad Medida', icon: 'pi pi-fw pi-credit-card', to: '/TipoUnidadMedida'},
								{label: 'Actividades', icon: 'pi pi-fw pi-credit-card', to: '/Actividades'},
								{label: 'Leyendas', icon: 'pi pi-fw pi-credit-card', to: '/Leyendas'},
								{label: 'Act. doc. Sector', icon: 'pi pi-fw pi-credit-card', to: '/ActividadesDocumentoSector'},
								{label: 'Producto Servicio', icon: 'pi pi-fw pi-credit-card', to: '/ProductoServicio'},
							]
								
						}
					]
				},
				// {
				// 	label: 'Utilities', icon:'pi pi-fw pi-compass',
				// 	items: [
				// 		{label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', to: '/icons'},
				// 		{label: 'PrimeFlex', icon: 'pi pi-fw pi-directions', url: 'https://www.primefaces.org/primeflex/', target: '_blank'}
				// 	]
				// },
				// {
                //     label: 'Prime Blocks', icon: 'pi pi-prime',
                //     items: [
                //         {label: 'Free Blocks', icon: 'pi pi-fw pi-eye', to: '/blocks', },
                //         {label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: 'https://www.primefaces.org/primeblocks-vue', target: '_blank'}
                //     ]
                // },
				// {
				// 	label: 'Pages', icon: 'pi pi-fw pi-briefcase',
				// 	items: [
				// 		{label: 'Crud', icon: 'pi pi-fw pi-pencil', to: '/crud'},
				// 		{label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', to: '/calendar'},
				// 		{label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline'},
				// 		{label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'pages/landing.html', target: '_blank'},
				// 		{label: 'Login', icon: 'pi pi-fw pi-sign-in', to: '/login'},
				// 		{label: 'Invoice', icon: 'pi pi-fw pi-dollar', to: '/invoice'},
				// 		{label: 'Wizard', icon: 'pi pi-fw pi-star', to: '/wizard'},
				// 		{label: 'Help', icon: 'pi pi-fw pi-question-circle', to: '/help'},
				// 		{label: 'Error', icon: 'pi pi-fw pi-times-circle', to: '/error'},
				// 		{label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', to: '/notfound'},
				// 		{label: 'Access Denied', icon: 'pi pi-fw pi-lock', to: '/access'},
				// 		{label: 'Empty', icon: 'pi pi-fw pi-circle-off', to: '/empty'}
				// 	]
				// },
				// {
				// 	label: 'Hierarchy', icon: 'pi pi-fw pi-align-left',
				// 	items: [
				// 		{
				// 			label: 'Submenu 1', icon: 'pi pi-fw pi-align-left',
				// 			items: [
				// 				{
				// 					label: 'Submenu 1.1', icon: 'pi pi-fw pi-align-left',
				// 					items: [
				// 						{label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-align-left'},
				// 						{label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-align-left'},
				// 						{label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-align-left'},
				// 					]
				// 				},
				// 				{
				// 					label: 'Submenu 1.2', icon: 'pi pi-fw pi-align-left',
				// 					items: [
				// 						{label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-align-left'},
				// 						{label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-align-left'}
				// 					]
				// 				},
				// 			]
				// 		},
				// 		{
				// 			label: 'Submenu 2', icon: 'pi pi-fw pi-align-left',
				// 			items: [
				// 				{
				// 					label: 'Submenu 2.1', icon: 'pi pi-fw pi-align-left',
				// 					items: [
				// 						{label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-align-left'},
				// 						{label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-align-left'},
				// 						{label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-align-left'},
				// 					]
				// 				},
				// 				{
				// 					label: 'Submenu 2.2', icon: 'pi pi-fw pi-align-left',
				// 					items: [
				// 						{label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-align-left'},
				// 						{label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-align-left'}
				// 					]
				// 				},
				// 			]
				// 		}
				// 	]
				// },
				// {
				// 	label: 'Start', icon: 'pi pi-fw pi-download',
				// 	items: [
				// 		{
				// 			label: 'Buy Now', icon: 'pi pi-fw pi-shopping-cart', url: 'https://www.primefaces.org/store'
				// 		},
				// 		{
				// 			label: 'Documentation', icon: 'pi pi-fw pi-info-circle', to: '/documentation'
				// 		}
				// 	]
				// }
			],
			menuActive: false,
			profileExpanded: false,
			themeColors: [
                {name: 'Blue', file: 'blue', color: '#0d6efd'},
                {name: 'Indigo', file: 'indigo', color: '#6610f2'},
                {name: 'Purple', file: 'purple', color: '#6f42c1'},
                {name: 'Pink', file: 'pink', color: '#d63384'},
                {name: 'Orange', file: 'orange', color: '#fd7e14'},
                {name: 'Yellow', file: 'yellow', color: '#ffc107'},
                {name: 'Green', file: 'green', color: '#198754'},
                {name: 'Teal', file: 'teal', color: '#20c997'},
                {name: 'Cyan', file: 'cyan', color: '#0dcaf0'},
			],
			layoutColors: [
                {name: 'Blue', file: 'blue', color: '#0d6efd'},
                {name: 'Indigo', file: 'indigo', color: '#6610f2'},
                {name: 'Purple', file: 'purple', color: '#6f42c1'},
                {name: 'Pink', file: 'pink', color: '#d63384'},
                {name: 'Orange', file: 'orange', color: '#fd7e14'},
                {name: 'Yellow', file: 'yellow', color: '#ffc107'},
                {name: 'Green', file: 'green', color: '#198754'},
                {name: 'Teal', file: 'teal', color: '#20c997'},
                {name: 'Cyan', file: 'cyan', color: '#0dcaf0'},
			],
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
		onDocumentClick() {
			if(!this.topbarItemClick) {
				this.activeTopbarItem = null;
				this.topbarMenuActive = false;
			}

			if(!this.menuClick) {
				if(this.isHorizontal() || this.isSlim()) {
					this.menuActive = false;
					EventBus.emit('reset-active-index');
				}

				this.hideOverlayMenu();
			}

			this.topbarItemClick = false;
			this.menuClick = false;
		},
		onProfileClick(event) {
			this.profileExpanded = !this.profileExpanded;
			if(this.isHorizontal() || this.isSlim()) {
				EventBus.emit('reset-active-index');
			}

			event.preventDefault();
		},
		onMenuClick() {
			this.menuClick = true;
		},
		onMenuItemClick(event) {
			if(!event.item.items) {
				EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}
			if(!event.item.items && this.isHorizontal()) {
				this.menuActive = false;
			}
		},
		onRootMenuItemClick() {
			this.menuActive = !this.menuActive;
		},
		onMenuButtonClick(event) {
			this.menuClick = true;

			this.rotateMenuButton = !this.rotateMenuButton;
			this.topbarMenuActive = false;

			if(this.layoutMode === 'overlay') {
				this.overlayMenuActive = !this.overlayMenuActive;
			}
			else {
				if(this.isDesktop())
					this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
				else
					this.staticMenuMobileActive = !this.staticMenuMobileActive;
			}

			event.preventDefault();
		},
		onTopbarMenuButtonClick(event) {
			this.topbarItemClick = true;
			this.topbarMenuActive = !this.topbarMenuActive;
			this.hideOverlayMenu();
			event.preventDefault();
		},
		onTopbarItemClick(event) {
			this.topbarItemClick = true;

			if(this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;

			event.originalEvent.preventDefault();
		},
		hideOverlayMenu() {
			this.rotateMenuButton = false;
			this.overlayMenuActive = false;
			this.staticMenuMobileActive = false;
		},
		isDesktop() {
			return window.innerWidth > 1024;
		},
		isHorizontal() {
			return this.layoutMode === 'horizontal';
		},
		isSlim() {
			return this.layoutMode === 'slim';
		},
		isMenuVisible() {
			if (this.isDesktop()) {
				if (this.layoutMode === 'static')
					return !this.staticMenuDesktopInactive;
				else if (this.layoutMode === 'overlay')
					return this.overlayMenuActive;
				else
					return true;
			} else {
				if (this.staticMenuMobileActive)
					return true;
				else if (this.layoutMode === 'overlay')
					return this.overlayMenuActive;
				else
					return true;
			}
		},
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
			this.staticMenuDesktopInactive = false;
			this.overlayMenuActive = false;

			if (this.isHorizontal()) {
				this.profileMode = 'top';
				this.profileMenuActive = false;
			}
		},
		onMenuColorChange(menuColor) {
            this.$emit('menu-color-change', menuColor);
		},
        changeLayoutScheme(scheme) {
            this.$emit('layout-scheme-change', scheme);
        },
		onProfileModeChange(profileMode) {
			this.profileMode = profileMode;
		},
		onLayoutColorChange(layout) {
            this.$emit('layout-change', layout);
		},
		onThemeChange(theme) {
            this.$emit('theme-change', theme);
		}
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
				'menu-layout-static': this.layoutMode !== 'overlay',
				'menu-layout-overlay': this.layoutMode === 'overlay',
				'layout-menu-overlay-active': this.overlayMenuActive,
				'menu-layout-slim': this.layoutMode === 'slim',
				'menu-layout-horizontal': this.layoutMode === 'horizontal',
				'layout-menu-static-inactive': this.staticMenuDesktopInactive,
				'layout-menu-static-active': this.staticMenuMobileActive,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
			}];
        },
		menuClass() {
			return ['layout-menu-container', {'layout-menu-dark': this.darkMenu}];
		}
    },
    components: {
        'AppTopBar': AppTopBar,
        // 'AppConfig': AppConfig,
        'AppInlineProfile': AppInlineProfile,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter
    }
}
</script>

<style lang="scss">
@import './App.scss';

</style>
