export default [
    
    {
		path: '/',
		component: () => import('@/modules/facturacion/empresa/views/ListadoViews.vue')
	},
    {
        path: '/empresa',
        name: 'empresa',
        component: () => import('@/modules/facturacion/empresa/views/ListadoViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
];