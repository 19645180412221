export default [
    {
        path: '/cufds/sucursal/:sucursalCod/puntoVenta/:puntoVentaCod',
        name: 'cufds',
        component: () => import('@/modules/facturacion/cufds/views/ListarCufdsViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    }, 
    {
        path: '/cufds',
        name: 'cufds1',
        component: () => import('@/modules/facturacion/cufds/views/ListarCufdsViews.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },   
];